const Portfolio = [
  {
    title: "SunSMS",
    url: "https://sunsms.fr",
    image: "/images/portfolio.15.png",
    description: `Une solution complète d'aggrateur SMS. Capable d'envoyer plusieurs millions de SMS par jour.<br><small>Nest.js, ElasticSearch, DynamoDB, k8s</small>`,
  },
  {
    title: "Tableau de bord marketing",
    url: "https://sofy.fr",
    image: "/images/portfolio.10.png",
    description: `Une plateforme marketing 360. Campagnes SMS, campagnes email, campagnes push. Click & Collect. Fidélité.<br><small>Vue, Nest.js, Redis, Mysql, Docker.</small>`,
  },
  {
    title: "Application Budy",
    url: "https://budy.gp",
    image: "/images/portfolio.9.png",
    description: `Une application Click & Collect et bons plans.<br><small>React Native, Nest.js, MongoDB, Mqtt. Paiement.</small>`,
  },
  {
    title: "Prise de RDV par internet",
    url: "https://tiringo.com",
    image: "/images/portfolio.13.png",
    description: `Une plateforme de prise de RDV par internet. Gestion d'agenda, rappel de rdv par SMS.<br><small>Vue, Nest.js, Mysql</small>`,
  },
  {
    title: "Appli web click & collect",
    url: "https://shop.budy.gp/partners/88",
    image: "/images/portfolio.12.png",
    description: `Une application web permettant de commande en click & collect.<br><small>Vue, Nest.js, MongoDB, Mqtt. Paiement</small>`,
  },
  {
    title: "Application point de vente Budy",
    url: "https://budy.gp",
    image: "/images/portfolio.11.png",
    description: `Un système POS permettant de recevoir des commandes click & collect, imprimer un bon de commande, livrer la commande via QrCode<br><small>Java, React Native. Mqtt, Websocket.</small>`,
  },
  {
    title: "Bot pour un jeu vidéo",
    image: "/images/portfolio.14.png",
    description: `Un bot permettant de compléter un mini jeu de manière autonome grace à la vision par ordinateur.<br><small>OpenCV, Python</small>`,
  },
  {
    title: "Ce portfolio",
    url: "https://stephenbolivar.com",
    image: "/images/portfolio.1.png",
    description: `Site web personnel.<br><small>React,Netlify</small>`,
  },
  {
    title: "Frontend Vue.js",
    url: "https://admin.zeewifi.com",
    image: "/images/portfolio.5.png",
    description: `Tableau de bord d'une application web marketing.<br><small>Vue, Laravel</small>`,
  },
  {
    title: "Frontend Nuxt.js",
    url: undefined,
    image: "/images/portfolio.6.png",
    description: `Un portail captif. Authentification à un réseau WIFI ouvert.<br><small>Vue (Nuxt), Laravel, Radius</small>`,
  },
  {
    title: "Progressive Web App ZeeWIFI",
    url: "https://app.zeewifi.com",
    image: "/images/portfolio.7.png",
    description: `Une application simple permetant d'afficher une carte interactive simple. <br><small>Vue (Quasar)</small>`,
  },
  {
    title: "Tiling Window Manager Notification",
    url: "https://github.com/sboli/twmn",
    image: "/images/portfolio.2.png",
    description: `Une application de bureau, permettant d'afficher des notifications
        correctement intégrées sur les gestionnaires des fenêtres dont tout l'espace est occupé par le contenu (tiling wm).<br><small>C++, Qt, boost</small>`,
  },
  {
    title: "Dominoes multiplayer",
    url: "https://play.google.com/store/apps/details?id=com.enjoyinformatique.dwf",
    image: "/images/portfolio.3.png",
    description: `Un jeu de dominos multijoueurs en ligne sur Android;<br><small>Java, LibGDX, Websocket, Node.js<small>`,
  },
  {
    title: "Frontend OCS Ericsson",
    url: undefined,
    image: "/images/portfolio.4.png",
    description: `Une application d'interface avec l'OCS Ericsson via son api XMLRPC. <br><small>PHP, JQuery</small>`,
  },
];

export default Portfolio;
