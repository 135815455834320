import aws from "simple-icons/icons/amazonaws";
import firebase from "simple-icons/icons/firebase";
import git from "simple-icons/icons/git";
import gcp from "simple-icons/icons/googlecloud";
import grafana from "simple-icons/icons/grafana";
import graphql from "simple-icons/icons/graphql";
import kubernetes from "simple-icons/icons/kubernetes";
import mongodb from "simple-icons/icons/mongodb";
import mysql from "simple-icons/icons/mysql";
import netlify from "simple-icons/icons/netlify";
import node from "simple-icons/icons/node-dot-js";
import rabbitmq from "simple-icons/icons/rabbitmq";
import react from "simple-icons/icons/react";
import redis from "simple-icons/icons/redis";
import sass from "simple-icons/icons/sass";
import vue from "simple-icons/icons/vue-dot-js";

const About = {
  image: "/images/web_developer.svg",
  htmlContent: `Je m'appelle Stephen, développeur passionné par les stacks full JavaScript,
avec une expertise particulière dans les architectures microservices et serverless.`,
  stack: [
    kubernetes,
    vue,
    react,
    sass,
    graphql,
    node,
    firebase,
    netlify,
    mongodb,
    mysql,
    redis,
    rabbitmq,
    gcp,
    aws,
    git,
    grafana,
  ],
};

export default About;
